import {
  CreateOfferFooterCancelBtnStyled,
  CreateOfferFooterCommissionTextStyled,
  CreateOfferFooterCourseTextStyled,
  CreateOfferFooterLeftStyled,
  CreateOfferFooterLeftTextStyled,
  CreateOfferFooterRightStyled,
  CreateOfferFooterStyled,
} from '../CreateOffer.styles';
import {
  Button,
  Form,
  FormInstance,
  Modal,
  notification,
} from '@pankod/refine-antd';
import CreateOfferExitModal from './modals/CreateOfferExitModal';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  createExchangePointOffer,
  useCreateOfferState,
} from '../../../widgets/CreateOfferForm';
import BigNumber from 'bignumber.js';
import { ExchangePointsContext } from '../../../shared/contexts/exchange-points-context';
import CreateOfferErrorsList from './CreateOfferErrorsList';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { CreateOfferError } from '../../../widgets/CreateOfferForm/model/request/createExchangePointOffer';
import { useTranslation } from 'react-i18next';
import { CheckCircleFilled } from '@ant-design/icons';
import { getOfferCommission } from '../../../shared/api/getOfferCommission/getOfferCommission';

interface ICreateOfferFooter {
  form: FormInstance;
}

const DEFAULT_CRYPTO_CURRENCY_CODE = 'USDTTRC20';

const CreateOfferFooter = ({ form }: ICreateOfferFooter) => {
  const { t } = useTranslation(['shared']);
  const navigate = useNavigate();

  const [isCreating, setIsCreating] = useState(false);
  const [edenexCommission, setEdenexCommission] = useState('0');
  const { activePoint } = useContext(ExchangePointsContext);

  const currency = Form.useWatch('cash_currency_code', form);
  const offerDirection = Form.useWatch('is_buying_crypto', form);

  const [isOpenedCancelModal, setIsOpenedCancelModal] = useState(false);

  useEffect(() => {
    getOfferCommission().then((r) => setEdenexCommission(r));
  });

  const directCourse = useCreateOfferState((state) => state.directCourse);
  const commission_percent = useCreateOfferState(
    (state) => state.commission_percent
  );
  const freezingPeriod = useCreateOfferState((state) => state.freezingPeriod);

  const clientCourse = useMemo(() => {
    if (!!directCourse && !!commission_percent) {
      return offerDirection === 1
        ? BigNumber(directCourse)
            .multipliedBy(1 - Number(commission_percent) / 100)
            .toFixed(2)
        : BigNumber(directCourse)
            .multipliedBy(1 + Number(commission_percent) / 100)
            .toFixed(2);
    } else return null;
  }, [commission_percent, directCourse, offerDirection]);

  const handleCancel = () => {
    handleOpenCancelModal();
  };

  const handleCreateAndPublishOffer = () => {
    const getSeconds = () => {
      const { minutes, hours, days } = freezingPeriod;
      return minutes * 60 + hours * 60 * 60 + days * 60 * 60 * 24;
    };
    form
      .validateFields()
      .then(async ({ course, is_buying_crypto, ...values }) => {
        setIsCreating(true);
        try {
          await createExchangePointOffer({
            ...values,
            is_buying_crypto: !!is_buying_crypto,
            crypto_currency_code: DEFAULT_CRYPTO_CURRENCY_CODE,
            exchange_rate: directCourse,
            exchange_freeze_seconds: getSeconds(),
            exchange_point_id: activePoint?.id,
          });

          notification.success({
            icon: (
              <CheckCircleFilled style={{ color: 'rgba(19, 194, 194, 1)' }} />
            ),
            message: String(t('shared:тексты.Оффер создан')),
            description: String(
              t(
                `shared:тексты.Оффер на ${
                  is_buying_crypto ? 'покупку' : 'продажу'
                } USDT успешно добавлен`
              )
            ),
            style: { borderLeft: '6px solid rgba(19, 194, 194, 1)' },
          });

          navigate('/offers');
        } catch (error) {
          if (error instanceof CreateOfferError) {
            const { errors } = error;

            Modal.warning({
              title: String(t('shared:тексты.Вы не можете создать оффер')),
              okText: String(t('shared:кнопки.Закрыть')),
              centered: true,
              width: 636,
              content: (
                <CreateOfferErrorsList
                  errors={errors}
                  pointName={activePoint?.name}
                />
              ),
            });
          }
        } finally {
          setIsCreating(false);
        }
      });
  };

  const handleCloseCancelModal = () => {
    setIsOpenedCancelModal(false);
  };

  const handleExitOffer = () => {
    navigate('/offers');
  };

  const handleOpenCancelModal = () => {
    setIsOpenedCancelModal(true);
  };

  return (
    <CreateOfferFooterStyled>
      <CreateOfferFooterLeftStyled>
        {clientCourse && currency ? (
          <>
            <CreateOfferFooterCourseTextStyled>
              {`${t(
                'shared:тексты.Курс клиента'
              )} 1:${clientCourse} ${currency.replace('CASH', '')}`}
            </CreateOfferFooterCourseTextStyled>
            <CreateOfferFooterCommissionTextStyled>
              {t('shared:тексты.Комиссия Edenex')}{' '}
              <span>{BigNumber(edenexCommission).toString()}%</span>
            </CreateOfferFooterCommissionTextStyled>
          </>
        ) : (
          <CreateOfferFooterLeftTextStyled>
            {t(
              'shared:тексты.Для отображения курса клиента заполните данные формы'
            )}
          </CreateOfferFooterLeftTextStyled>
        )}
      </CreateOfferFooterLeftStyled>
      <CreateOfferFooterRightStyled>
        <CreateOfferFooterCancelBtnStyled type="link" onClick={handleCancel}>
          {t('shared:кнопки.Отмена')}
        </CreateOfferFooterCancelBtnStyled>
        <Button
          type="primary"
          onClick={handleCreateAndPublishOffer}
          loading={isCreating}
        >
          {t('shared:тексты.Создать и опубликовать оффер')}
        </Button>
      </CreateOfferFooterRightStyled>
      <CreateOfferExitModal
        isOpen={isOpenedCancelModal}
        onOk={handleExitOffer}
        onCancel={handleCloseCancelModal}
      />
    </CreateOfferFooterStyled>
  );
};
export default CreateOfferFooter;
