import {
  RequestInfoHeader,
  RequestInfoWrapper,
  Title,
} from './RequestInfo.styles';
import { Typography } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';
import { Flex } from '../../../../shared/components/styled';
import { Tag } from 'antd';
import countryList from 'react-select-country-list';
import Timer from './Timer';
import React, { useMemo } from 'react';
import { IInfoList, IRequest } from '../../model/types';
import {
  EnvironmentOutlined,
  InfoCircleOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import RequestInfoItem from './RequestInfoItem';
import { EOfferRequestStatusTagColors } from '../../model/enums';
import {
  EExchangeRateSource,
  EOfferRequestStatuses,
} from '../../../../shared/enums';
import dayjs from 'dayjs';
import ActionsBtnBlock from './ActionsBtnBlock';
import { ModalKeys } from '../../../../shared/components/ui/RenderModal/model/enums';
import { useModalState } from '../../../../shared/components/ui/RenderModal/model/useModalState';
import { RenderModal } from '../../../../shared/components/ui/RenderModal';
import { useNavigate } from '@pankod/refine-react-router-v6';
import BN from 'bignumber.js';

type RequestStatus =
  | 'pending'
  | 'accepted'
  | 'cancelled'
  | 'warranty_collateral'
  | 'ready'
  | 'expired'
  | 'on_argument'
  | 'completed';

const needAddedInfo: RequestStatus[] = [
  'ready',
  'accepted',
  'warranty_collateral',
];

interface IProps {
  request: IRequest;
  refetchRequest: () => Promise<void>;
}

const RequestInfo = ({ request, refetchRequest }: IProps) => {
  const { t, i18n } = useTranslation('shared');
  const navigate = useNavigate();
  const { openModal } = useModalState();

  function formatDuration(seconds: number) {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    return `${days}${t('shared:тексты.д')} : ${hours
      .toString()
      .padStart(2, '0')}${t('shared:тексты.ч')} : ${minutes
      .toString()
      .padStart(2, '0')}${t('shared:тексты.м')}`;
  }

  let tempCashAmount: string;
  let tempCryptoAmount: string;

  if (!request.offer.is_buying_crypto) {
    // ПО продает актив
    const temp = BN(request.crypto_amount).multipliedBy(
      BN(100).div(100 - Number(request.offer.initial_commission_percent))
    );
    const temp2 = BN(temp).plus(request.crypto_commission_amount);
    tempCashAmount = BN(temp2).multipliedBy(request.client_rate).toFixed(2);
    const temp3 = BN(tempCashAmount)
      .div(request.client_rate)
      .minus(request.crypto_commission_amount);
    tempCryptoAmount = temp3.toFixed(8);
  }
  if (request.offer.is_buying_crypto) {
    // ПО покупает актив
    const temp = BN(request.crypto_amount).minus(
      BN(request.crypto_amount).multipliedBy(
        BN(request.offer.initial_commission_percent).div(100)
      )
    );
    tempCashAmount = BN(temp)
      .minus(request.crypto_commission_amount)
      .multipliedBy(request.client_rate)
      .toFixed(2);
    const temp2 = BN(tempCashAmount)
      .div(request.client_rate)
      .plus(request.crypto_commission_amount);
    tempCryptoAmount = temp2.toFixed(8);
  }

  const exchangePointBlock: IInfoList = useMemo(() => {
    const initInfo = {
      header: {
        title: request.offer?.is_buying_crypto
          ? t('shared:тексты.Пункт обмена получает')
          : t('shared:тексты.Пункт обмена отдает'),
        icon: <EnvironmentOutlined />,
      },
      items: [
        {
          label: t('shared:тексты.Актив двоеточие'),
          value: `${tempCryptoAmount} ${request.offer?.crypto_payment_system_currency?.currency_key}`,
        },
        {
          label: t('shared:тексты.Комиссия пункта обмена двоеточие'),
          value: `${request.commission_percent}%`,
        },
        {
          label: t('shared:тексты.Фикс точка стоимость сделки двоеточие'),
          value: `${request.crypto_commission_amount} ${request.offer?.crypto_payment_system_currency?.currency_key}`,
        },
        {
          label: t('shared:тексты.Пункт обмена двоеточие'),
          value: request.offer?.exchange_point?.name,
          link: `/points/${request.offer?.exchange_point_id}`,
        },
      ],
    };

    if (needAddedInfo.includes(request.status)) {
      initInfo.items.splice(3, 0, {
        label: request.offer?.is_buying_crypto
          ? t('shared:тексты.Как выдать наличные двоеточие')
          : t('shared:тексты.Как получить наличные двоеточие'),
        value: String(request?.cash_user?.username),
        link: `/staff/${request?.cash_user?.id}`,
      });
    }

    return initInfo;
  }, [request, i18n.language]);

  const clientBlock = useMemo(() => {
    const initInfo: IInfoList = {
      header: {
        title: request.offer?.is_buying_crypto
          ? t('shared:тексты.Клиент получает')
          : t('shared:тексты.Клиент отдает'),
        icon: <UserSwitchOutlined />,
      },
      items: [
        {
          label: t('shared:тексты.Наличные двоеточие'),
          value: `${tempCashAmount} ${request.offer?.cash_payment_system_currency?.currency_key}`,
        },
        {
          label: t('shared:тексты.Курс клиента двоеточие'),
          value: `${request.client_rate} ${request.offer?.cash_payment_system_currency.currency_key}`,
        },
        {
          label: t('shared:тексты.Клиент') + ':',
          value: `${!!request.client ? request.client.name : request.nickname}`,
          link: `${!!request.client ? `/clients/${request.client.id}` : ''}`,
        },
      ],
    };

    if (needAddedInfo.includes(request.status)) {
      initInfo.items.push({
        label: t('shared:тексты.Дата встречи двоеточие'),
        value: dayjs(request.exchange_datetime).format('DD.MM.YYYY HH:mm'),
      });
    }

    return initInfo;
  }, [request, i18n.language]);

  const generalInfoBlock = useMemo(() => {
    const items = [
      {
        label: t('shared:тексты.Оффер двоеточие'),
        value: request.offer?.name,
        link: `/offers/${request.offer?.id}`,
      },
      {
        label: t('shared:тексты.Курс без комиссии двоеточие'),
        value: `${request.exchange_rate} ${request.offer?.cash_payment_system_currency.currency_key}`,
      },
      {
        label: t('shared:тексты.Источник курса двоеточие'),
        value: EExchangeRateSource[request.offer?.exchange_rate_source],
      },
      {
        label: t('shared:тексты.Срок заморозки курса двоеточие'),
        value: formatDuration(request.offer?.exchange_freeze_seconds),
      },
    ];

    if (request.status === 'completed') {
      items.splice(1, 0, {
        label: `${t('shared:тексты.Операция')}:`,
        value: `${request?.operations_chain?.id}`,
        link: `/chains/${request?.operations_chain?.id}`,
      });
    }

    return {
      header: {
        title: t('shared:тексты.Общая информация'),
        icon: (
          <InfoCircleOutlined
            style={{
              color: 'rgba(0, 0, 0, 0.88)',
            }}
          />
        ),
      },
      items,
    };
  }, [request, i18n.language]);

  const getCountry = (countryCode: string) =>
    countryList().data.find((country) => country.value === countryCode)?.label;

  const handleRedirectToConfirm = (id: number) => {
    navigate(`/offers/confirm-request/${id}`);
  };

  const actionButtonClickHandler = (trigger: string) => {
    const modalProps = {
      id: request.id,
      isBuyingCrypto: request.offer?.is_buying_crypto,
      name: request?.name,
      status: request.status,
      currency: 'USDT',
      refetchData: refetchRequest,
    };

    switch (trigger) {
      case 'completed': {
        openModal(ModalKeys.ModalConfirmReceiptOfCash, modalProps);
        break;
      }
      case 'expired':
      case 'cancelled': {
        modalProps.status = request.status;
        openModal(ModalKeys.ModalCancelTransaction, modalProps);
        break;
      }
      case 'on_argument': {
        modalProps.currency = 'USDT';
        openModal(ModalKeys.ModalOpenDispute, modalProps);
        break;
      }
      case 'accepted': {
        handleRedirectToConfirm(request.id);
        break;
      }
    }
  };

  return (
    <RequestInfoWrapper>
      <RequestInfoHeader>
        <Flex align={'flex-start'}>
          <Flex gap={4} justify={'flex-start'} align={'flex-start'} vertical>
            <Flex gap={16}>
              <Title level={3}>
                {request.offer?.is_buying_crypto
                  ? t('shared:тексты.Сделка на покупку n за наличные m', {
                      currency:
                        request.offer?.crypto_payment_system_currency
                          .currency_key,
                      cashCurrency:
                        request.offer?.cash_payment_system_currency
                          .currency_key,
                    })
                  : t('shared:тексты.Сделка на продажу n за наличные m', {
                      currency:
                        request.offer?.crypto_payment_system_currency
                          .currency_key,
                      cashCurrency:
                        request.offer?.cash_payment_system_currency
                          .currency_key,
                    })}
              </Title>
              <Tag color={EOfferRequestStatusTagColors[request.status]}>
                {t(`shared:статусы.${EOfferRequestStatuses[request.status]}`)}
              </Tag>
            </Flex>
            <Typography.Text type="secondary">{request?.name}</Typography.Text>
          </Flex>
          <ActionsBtnBlock
            transitionStatuses={request.allowed_statuses}
            callBack={actionButtonClickHandler}
            dealCurrentStatus={request.status}
            isBuyCrypto={!request.offer.is_buying_crypto}
          />
        </Flex>
        {request.status !== 'pending' && (
          <Flex>
            <Flex align={'flex-start'} vertical>
              <Typography.Text type="secondary">
                {t('shared:тексты.Место встречи')}
              </Typography.Text>
              <Typography.Text strong>
                {request.exchange_address ?? '-'}
              </Typography.Text>
            </Flex>
            {request.status !== 'on_argument' && (
              <Timer
                deadline={
                  request.exchange_datetime &&
                  (request.status === 'accepted' ||
                    request.status === 'warranty_collateral')
                    ? dayjs(request.exchange_datetime).toString()
                    : dayjs().toString()
                }
              />
            )}
          </Flex>
        )}
      </RequestInfoHeader>
      <Flex align={'flex-start'}>
        <RequestInfoItem {...exchangePointBlock} />
        <RequestInfoItem {...clientBlock} />
        <RequestInfoItem {...generalInfoBlock} />
      </Flex>
      <RenderModal currentModalKey={ModalKeys.ModalConfirmReceiptOfCash} />
      <RenderModal currentModalKey={ModalKeys.ModalOpenDispute} />
      <RenderModal currentModalKey={ModalKeys.ModalCancelTransaction} />
    </RequestInfoWrapper>
  );
};

export default RequestInfo;
